<template>
    <div>
      <main>
        <!-- 麵包屑 -->
        <section class="container" data-aos="fade-down"  data-aos-delay="500">
          <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb" class="badge">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
              <li class="breadcrumb-item" aria-current="page"><router-link to="unintended-2">我意外懷孕了</router-link></li>
              <li class="breadcrumb-item" aria-current="page"><router-link to="unintended-3-1">我還沒準備好生下來</router-link></li>
              <li class="breadcrumb-item" aria-current="page"><router-link to="act-4-2">醫療議題</router-link></li>
              <li class="breadcrumb-item" aria-current="page"><router-link to="unintended-4-2">無法自行撫養</router-link></li>
              <li class="breadcrumb-item" aria-current="page"><router-link to="unintended-5-7">出養</router-link></li>
              <li class="breadcrumb-item active" aria-current="page"><router-link to="unintended-5-7">社福資源</router-link></li>
            </ol>
          </nav>
        </section>

        <!-- 主要區塊 -->
        <section class="container step-rwd">
          <div class="step">
            <!-- 裝飾用圖片 -->
            <img class="step-circle" src="../../assets/image/front/image_circleword.svg">
            <img class="image1" src="../../assets/image/front/page02_image1.svg">
            <img class="image2" src="../../assets/image/front/page02_image2.svg">
            <img class="image3" src="../../assets/image/front/page02_image3.svg">
            <!-- 結束 -->
            <div class="text-box">
              <p class="step-text" data-aos="fade-up" >ANSEWR</p>
              <h2 class="step-title" data-aos="fade-up" >選擇・答案</h2>
              <img class="title-line" src="../../assets/image/front/choose_title_line.svg" data-aos="fade-up" >
              <p class="step-sologan" data-aos="fade-up" >為自己所想，為自己所選。</p>
            </div>
            <div class="container step-box">
              <div class="step-box-inner">
                <div class="box-title" data-aos="fade-up">
                  <h2>｜社福資源｜</h2>
                </div>
                <div class="select-menu">
                  <img class="mb-60" data-aos="fade-up" src='../../assets/image/front/page02_pic3.jpg'>
                  <hr data-aos="fade-up">
                    <!-- 選項開始 -->
                    <ul class="select-item container-inner mt-60">
                      <li data-aos="fade-up">
                        <h3>收養家庭會好好照顧我的小孩嗎?</h3>
                        <p>收養人在正式收養小孩之前，必須先與孩子進入共同生活期3-6個月的時間，這期間須繳交小孩生活概況的報告與照片，讓機構進行追蹤，若發現小孩出現不適應、收養家庭不適任等等，都會暫停此次的收養。評估小孩適應良好之後，才會開始進行法律程序。即便出養後，機構也會持續追蹤小孩在收養家庭的情況，你也可以透過機構了解寶寶的資訊與概況。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>我還可以去看我的小孩嗎?</h3>
                        <p>在完成出養的法定程序之前是可以的，你只要是尋求正式的出養管道，領有收出養媒合許可的單位，在法院判決前，皆會視你的需求來安排你與孩子的互動與接觸。但一旦經過法院判決，孩子之親權完整屬於收養家庭後，其決定權則需多參照收養家庭之意願。而建議若你有此期待需在進入出養程序時即向機構表達，以利在媒合過程協助尋覓可接受此期待之收養家庭，又或是在裁定前協助你與收養家庭達其共識。在以前社會還是很保守時，小媽媽的權利是被忽視的，覺得直接將小孩送給別人就不會傷心難過，然而當小媽媽越不知道寶寶的訊息，越會胡思亂想，因為她不知道我的小孩怎麼了。因此現在機構們突破以往的方式，鼓勵小孩在機構被安置、等待收養人的期間，你可以多來探視小孩。在出養之前，你會透過機構了解收養家庭，明白小孩會在什麼樣的環境中成長，並且與收養家庭達成共識，以照片或是定期訪視等方式與小孩保持聯繫。收養人在試養小孩期間，須繳交小孩生活概況的報告與照片，一方面讓機構追蹤，同時也保障你的權益。出庭當日，或許是你最後一次與小孩實際接觸。當小孩真的成為另一個家庭的成員時，因考量到小孩需要在一個穩定的關係中建立家庭關係，不建議你私自(下)去關心孩子的。你可以透過機構，由他們代為聯絡收養家庭，請他們提供孩子的資訊與照片。 </p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>出養過程中，我可以改變心意嗎? </h3>
                        <p>在法院做出判決以前，你有權利可以改變心意。然而一旦小孩的親權與監護權已轉移至收養家庭後，你如果改變心意，這時你必須負擔民法的賠償責任。更重要的是，小孩會因為你的決定，一次又一次地適應新的環境，這對小孩的成長並不是一件好事。所以在程序當中時，務必要好好想清楚，這時也能好好與協助你的社工一起討論。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>我可以找哪些機構協助?</h3>
                        <ul class="list mb-60">
                          <li>• 財團法人一粒麥子社會福利慈善事業基金會(臺東) <br>
                          089-960168 </li>
                          <li>• 財團法人中華民國兒童福利聯盟文教基金會(臺北/臺中/高雄)<br>
                          02-25585806#8<br>
                          04-23780095<br>
                          07-3501959
                          </li>
                          <li>• 財團法人忠義社會福利事業基金會(臺北)<br>
                          02-22301100
                          </li>
                          <li>• 財團法人勵馨社會福利慈善事業基金會(臺北/臺中/高雄)<br>
                          02-23690885<br>
                          04-22239595<br>
                          07-2237995
                          </li>
                          <li>• 財團法人天主善牧社會福利基金會(臺南)<br>
                          06-2344009<br>
                          06-2344009*16
                          </li>
                          <li>• 財團法人台北市基督徒救世會社會福利事業基金會(臺北)<br>
                          02-27290265
                          </li>
                          <li>• 財團法人高雄私立小天使家園(高雄)<br>
                          07-6960777
                          </li>
                          <li>• 財團法人天主教福利會(新北)<br>
                          02-26625184
                          </li>
                          <li>• 神愛兒童之家(宜蘭) <br>
                          03-9514652
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <!-- 選項結束 -->
                    <hr data-aos="fade-up">
                    <div data-aos="fade-up">
                      <a class="goBack" onclick="history.back()" value="回到上一頁">回到上一頁</a>
                      <a class="goBack" href="#">返回首頁</a>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Consult></Consult>
        <Circle></Circle>
      </main>
    </div>
</template>
<script>
import Consult from '../../components/Consult.vue'
export default {
  data () {
    return {
    }
  },
  mounted () {
  },
  components: {
    Consult
  }
}
</script>
